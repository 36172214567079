<template>
  <img alt="Vue logo" :src="require('@assets/logo.png')">
  <div>这个是submodule/index</div>
  <hello-world/>
</template>

<script>

import HelloWorld from "@/components/HelloWorld";

export default {
  name: 'indexPage',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
